/**
* ./stores/ApplicationSettings.ts
*/

/**
 * Stores common site settings. Initially populated from the default layout.
 */
export const useApplicationSettingsStore = defineStore('applicationSettings', () => {
    const settings = ref<IApplicationSettingCollection>({});

    // ACTIONS
    function setSettings(newSettings: IApplicationSetting[]) {
        newSettings.forEach((setting: IApplicationSetting) => {
            settings.value[setting.key] = setting;
        });
    }

    // GETTERS
    function getSetting(key: string): string | undefined {
        return settings.value[key]?.value;
    }

    return {
        // State
        settings,

        // Actions
        setSettings,

        // Getters
        getSetting,
    }

});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useApplicationSettingsStore, import.meta.hot));
}

